import React, { useEffect, useState, useMemo } from 'react';
import { Button, Label, Icon } from '@iq/react-components';
import { useSelector } from 'react-redux';

import SimpleModal from '../SimpleModal';
import Indicator from '../panels/EventTimelinePanel/Indicator';

import { getStaticComponents } from '../../bundles/components';
import { getTimezone } from '../../bundles/application';
import { useSelect$, useSubscribe } from '../../utils';
import { formatSiteDatetime } from '../../datetimeUtils';
import { SEVERITIES, FORM_STATES } from '../../constants';

function showLabelledValue(value, label, transformer, showLabel = true, emptyValue = 'None') {
  if (!value && !showLabel) return null;
  let displayValue = emptyValue;
  if (value && (typeof value !== 'object' || value.length > 0)) {
    displayValue = transformer ? transformer(value) : value;
  }
  return (
    <>
      <Label>{label}</Label>
      <div className={`value-container ${displayValue === emptyValue ? 'empty-value' : ''}`}>
        {displayValue}
      </div>
    </>
  );
}

const IotEventModal = ({
  event: readOnlyEvent,
  hasHistory = false,
  onBack = () => null,
  onClose,
}) => {
  const [event, setEvent] = useState(readOnlyEvent);

  useEffect(() => {
    setEvent(readOnlyEvent);
  }, [readOnlyEvent]);

  const {
    description,
    timestamp,
    severity,
    message,
    origin,
    active,
    sender,
    component: iotEventComponents,
    remoteSource,
    suggestions = [],
  } = event;

  const timezone = useSelector(getTimezone);
  const components$ = useSelect$(getStaticComponents);
  const staticComponents = useSubscribe(components$, components$.value);

  const iotComponentDesignations = useMemo(() => {
    let comps = iotEventComponents || [];
    if (typeof iotEventComponents === 'string') {
      comps = [iotEventComponents];
    }
    if (comps.length && staticComponents) {
      return comps.map(
        (comp) => (staticComponents.find((c) => c.id === comp) || {}).itemDesignation
      );
    }
    return [];
  }, [iotEventComponents, staticComponents]);

  const getRenderValueList = (options = {}) => {
    const { inline = true, distinct = true } = options;
    return (values) => (
      <div className={`value-list ${inline ? 'inline' : ''}`}>
        {values.map((value, i) => (
          <div
            key={i}
            className={`value-list__value ${distinct ? 'distinct' : ''}`}
          >
            {value}
          </div>
        ))}
      </div>
    );
  };

  // add checks here to make sure state has been updated
  if (!event?.timestamp) {
    return null;
  }

  return event?.timestamp ? (
    <SimpleModal
      size="l"
      onClose={onClose}
      className={`event-modal-wrapper ${FORM_STATES.view}`}
      headerLeft={
        hasHistory ? (
          <Button
            design="text"
            tooltip="Back"
            onClick={onBack}
          >
            <Icon icon="he-previous" />
          </Button>
        ) : undefined
      }
      title={
        <div className="event-modal-header">
          <Indicator
            severity={severity}
            round={false}
            size={12}
          />
          <div>{message || 'IOT Event'}</div>
        </div>
      }
    >
      <div className={`event-modal ${FORM_STATES.view}`}>
        <div className={`top-section`}>
          <div className={`top-section__content iot`}>
            <div className="event-modal__attribute-group">
              {showLabelledValue(sender, 'Sender')}
            </div>
            <div className="event-modal__attribute-group">
              {showLabelledValue(active?.toString(), 'Active')}
            </div>
          </div>
        </div>
        <div className={`body-section iot`}>
          <div className="event-modal__column">
            <div className="event-modal__attribute-group">
              {showLabelledValue(description || message, 'Description')}
            </div>
            <div className="event-modal__attribute-group">
              {showLabelledValue(origin, 'Origin')}
            </div>
            <div className="event-modal__attribute-group">
              {showLabelledValue(remoteSource, 'Remote source')}
            </div>
            <div className="event-modal__attribute-group">
              {showLabelledValue(iotComponentDesignations, 'Components', getRenderValueList())}
            </div>
          </div>
          <div className="event-modal__column">
            <div className="event-modal__attribute-group">
              {showLabelledValue(formatSiteDatetime(timestamp, timezone, true), 'Occurred at')}
            </div>
            <div className="event-modal__attribute-group">
              {showLabelledValue(SEVERITIES[severity], 'Severity')}
            </div>
            <div className="event-modal__attribute-group">
              {showLabelledValue(
                suggestions,
                'Suggestions',
                getRenderValueList({ inline: false, distinct: false })
              )}
            </div>
          </div>
        </div>
      </div>
    </SimpleModal>
  ) : null;
};

export default IotEventModal;
