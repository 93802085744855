import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@iq/react-components';
import { readFile } from '../../../../utils';

import JSONEditor from '../../../JSONEditor';
import SimpleModal from '../../../SimpleModal';

const CreateTaskModal = ({ schema, uiSchema, onSubmit, onCloseModal }) => {
  const [formData, setFormData] = useState({});
  const [filename, setFilename] = useState(null);
  const [error, setError] = useState(false);

  const onDrop = async (accepted) => {
    if (accepted.length > 0) {
      const fileContent = await readFile(accepted[0]);
      const config = JSON.parse(fileContent);

      setError(false);
      setFormData({ ...formData, config: JSON.stringify(config, null, 4) });
      setFilename(accepted[0].name);
    } else {
      setError(true);
    }
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    accept: { 'application/json': [] },
    onDrop,
  });

  function onFormChange({ formData: data }) {
    setFormData(data);
  }

  function onFormSubmit({ formData: data }) {
    onSubmit(data);
    onCloseModal();
  }

  return (
    <SimpleModal
      onClose={onCloseModal}
      title="Add task"
    >
      <div className="create-task-content">
        <h2>Task</h2>

        <p>
          An algorithm task is what defines the conditions of when and how the algorithm should run.
          <br />
          <br />
          Here you can upload related configuration files for the algorithm, define what conditions
          triggers the algorithm and how the output file/files are labeled.
          <br />
          <br />
          The algorithm will be triggered on files of Comtrade format and the algorithm will be run
          in a Matlab enabled environment.
        </p>

        <ul
          className="create-task-content-file upload-modal-choices"
          {...getRootProps()}
        >
          <li>
            <div className="meta">
              {error && (
                <div className="upload-error">
                  <div>Config files should be valid JSON.</div>
                </div>
              )}
              {filename && <div className="title">{filename}</div>}
            </div>
            <div className="actions">
              <div className="upload-button">
                <input {...getInputProps()} />
                <Button onClick={open}>Upload file</Button>
              </div>
            </div>
          </li>
        </ul>

        <JSONEditor
          formData={formData}
          schema={schema}
          uiSchema={uiSchema}
          onFormSubmit={onFormSubmit}
          saveButtonText={'Create task'}
          initialEditMode={true}
          showEditButton={false}
          cancelCallback={onCloseModal}
          onFormChange={onFormChange}
        />
      </div>
    </SimpleModal>
  );
};

export default CreateTaskModal;
