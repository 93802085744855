/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@iq/react-components';
import { DateTimePicker } from '../../DateTimePicker';
import './DateTimePickerField.scss';
import { getTimezone } from '../../../bundles/application';
import { formatSiteDatetime } from '../../../datetimeUtils';

const getValues = (formData, ranged) => {
  if (!formData) {
    return [null, null, null];
  }
  if (ranged) {
    return [formData.from, formData.to, null];
  }
  if (Number.isNaN(Date.parse(formData))) {
    // if not a parsable date we a have relative value
    return [null, null, formData];
  }
  // single date
  return [formData];
};

/*
 * Field which accepts either an object with to and from keys with Date values, a single Date, or a single relative time string
 * The rangedSelect prop determines if multiple datetimes can be selected (relative times not available)
 */
const DateTimePickerField = (props) => {
  const {
    schema: {
      title,
      isRequired,
      editable = true,
      relativeOptions = [],
      withRelativeOptions,
      rangedSelect,
    },
    formData,
    onChange,
    errorSchema: {
      from: { __errors: fromErrors } = { __errors: [] },
      to: { __errors: toErrors } = { __errors: [] },
    },
  } = props;
  const timezone = useSelector(getTimezone);

  const values = getValues(formData, rangedSelect);
  const [isoStart, setIsoStart] = useState(values[0]); // utc, ISO string
  const [isoEnd, setIsoEnd] = useState(values[1]); // utc, ISO string
  const [relativeValue, setRelativeValue] = useState(values[2]);
  const [showAddEndTimeButton, setShowAddEndTimeButton] = useState(
    rangedSelect && (!isoEnd || isoStart === isoEnd)
  );
  const [showEnd, setShowEnd] = useState(values[1] && values[1] !== values[0]);

  const START_ID = `${title}-start`;
  const END_ID = `${title}-end`;

  const startLabel = `${title} date`;
  const startLabelAlt = `${title} start date`;
  const endLabel = `${title} end date`;

  const cleanedFromErrors = fromErrors.filter((e) => e !== 'must be string');
  const cleanedToErrors = toErrors.filter((e) => e !== 'must be string');

  useEffect(() => {
    if (rangedSelect) {
      onChange({
        from: isoStart,
        to: isoEnd,
      });
    } else if (isoStart) {
      onChange(isoStart);
    } else {
      onChange(relativeValue);
    }
  }, [isoStart, isoEnd, relativeValue]);

  const handleChange = (inputId) => (valueType, value) => {
    if (value && inputId === START_ID) {
      if (valueType === 'relative') {
        setRelativeValue(value);
        setIsoStart(null);
        setIsoEnd(null);
      }
      if (valueType === 'absolute') {
        setIsoStart(value);
        setRelativeValue(null);
      }
    }
    if (value && inputId === END_ID && valueType === 'absolute') {
      setIsoEnd(value);
      setRelativeValue(null);
    }
  };

  return editable ? (
    <>
      <div className={`date-time-picker editing ${!showAddEndTimeButton ? 'upper' : ''}`}>
        <div className={`title`}>
          {rangedSelect && !showAddEndTimeButton && (
            <label>{`${startLabelAlt}${isRequired || showEnd ? '*' : ''}`}</label>
          )}
          {(!rangedSelect || (rangedSelect && showAddEndTimeButton)) && (
            <label>{`${startLabel}${isRequired ? '*' : ''}`}</label>
          )}
          <div className="title-actions">
            {rangedSelect && showAddEndTimeButton && (
              <div>
                <Button
                  activity="secondary"
                  slim
                  onClick={() => {
                    setShowAddEndTimeButton(false);
                    setShowEnd(true);
                  }}
                  type="button"
                >
                  {`Add end time`}
                </Button>
              </div>
            )}
            {!isRequired && isoStart && (
              <div>
                <Button
                  activity="secondary"
                  slim
                  onClick={() => {
                    setIsoStart(null);
                    if (isoEnd) {
                      setShowAddEndTimeButton(true);
                      setShowEnd(false);
                      setIsoEnd(null);
                    }
                  }}
                  type="button"
                >
                  {`Remove`}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className={`date-time-picker__picker`}>
          <DateTimePicker
            id={START_ID}
            value={isoStart}
            relativeValue={relativeValue}
            relativeOptions={relativeOptions}
            withRelativeOptions={withRelativeOptions}
            onChange={handleChange(START_ID)}
          />
        </div>
        {cleanedFromErrors.length ? (
          <>
            {cleanedFromErrors.map((e, i) => (
              <div
                className="errors"
                key={`error-${i}`}
              >
                {e}
              </div>
            ))}
          </>
        ) : null}
      </div>
      {rangedSelect && showEnd && (
        <div className={`date-time-picker editing`}>
          <div className={`title`}>
            <label>{`${endLabel}`}</label>
            <div>
              <Button
                activity="secondary"
                slim
                onClick={() => {
                  setShowAddEndTimeButton(true);
                  setShowEnd(false);
                  setIsoEnd(null);
                }}
                type="button"
              >
                {`Remove`}
              </Button>
            </div>
          </div>
          <div className={`date-time-picker__picker`}>
            <DateTimePicker
              id={END_ID}
              value={isoEnd || isoStart}
              onChange={handleChange(END_ID)}
            />
          </div>
          {cleanedToErrors.length ? (
            <>
              {cleanedToErrors.map((e, i) => (
                <div
                  className="errors"
                  key={`error-${i}`}
                >
                  {e}
                </div>
              ))}
            </>
          ) : null}
        </div>
      )}
    </>
  ) : (
    <div className={`date-time-picker`}>
      <div className="date-info-wrapper">
        <div className={`title`}>
          <label>{`${isoEnd ? startLabelAlt : startLabel}`}</label>
        </div>
        <div className="time">
          {isoStart ? formatSiteDatetime(isoStart, timezone) : relativeValue}
        </div>
      </div>

      {rangedSelect && showEnd && isoEnd && (
        <div className="date-info-wrapper">
          <div className={`title`}>
            <label>{`${endLabel}`}</label>
          </div>
          <div className="time">{formatSiteDatetime(isoEnd, timezone)}</div>
        </div>
      )}
    </div>
  );
};

export default DateTimePickerField;
