/* eslint-disable no-sparse-arrays */
import { getTime, getDate, getMonth, getYear, getMinutes, getSeconds } from 'date-fns';
import { fromZonedTime, toZonedTime, format } from 'date-fns-tz';
import { pad } from '@rjsf/utils';
import { GRANULARITY_OPTIONS } from './constants';

/*
 * date-fns-tz functions convert Dates' times to times that would be displayed
 * if in the specified timezone. This is useful for displaying times in the
 * application and datetime pickers, but not for storing times in the database
 * as the timezone (i.e. the offset) is not altered.
 */

function utcToSite(utcDatetime, tz) {
  if (!utcDatetime) return null;
  return toZonedTime(utcDatetime, tz);
}

function siteToUtc(siteDatetime, tz) {
  if (!siteDatetime) return null;
  return fromZonedTime(siteDatetime, tz);
}

function datetimeString(utcDatetime, timeZone, withMs = false, withTz = false) {
  const pattern = `yyyy-MM-dd HH:mm:ss${withMs ? ':SSS' : ''}${withTz ? ' zzz' : ''}`;
  return format(utcDatetime, pattern, { timeZone });
}

function zonedDateAndTimeToUtc(zonedTimestamp, zonedTime, timezone) {
  const zonedDate = utcToSite(zonedTimestamp, timezone);
  const [hours, minutes, seconds] = zonedTime.split(':');
  zonedDate.setHours(hours, minutes, seconds, 0);

  return siteToUtc(zonedDate, timezone);
}

const formatSiteDatetime = (timestamp, timezone, withMs = false) =>
  datetimeString(utcToSite(timestamp, timezone), timezone, withMs);

function getSiteTime(localTimestamp, timezone) {
  if (!localTimestamp) return null;
  const siteDate = utcToSite(localTimestamp, timezone);
  return [
    pad(siteDate.getHours(), 2),
    pad(siteDate.getMinutes(), 2),
    pad(siteDate.getSeconds(), 2),
  ].join(':');
}

const getMonthFromNow = (difference) => {
  const d = new Date();
  d.setMonth(d.getMonth() + difference);
  d.setHours(0, 0, 0);
  d.setMilliseconds(0);
  return d.getTime();
};

const getDayFromNow = (difference) => {
  const d = new Date();
  d.setDate(d.getDate() + difference);
  d.setHours(0, 0, 0);
  d.setMilliseconds(0);
  return d.getTime();
};

const getHourFromNow = (difference) => {
  const d = new Date();
  d.setHours(d.getHours() + difference);
  d.setMilliseconds(0);
  return d.getTime();
};

const getMinutesFromNow = (difference) => {
  const d = new Date();
  d.setMinutes(d.getMinutes() + difference);
  d.setMilliseconds(0);
  return d.getTime();
};

const getSecondsFromNow = (difference) => {
  const d = new Date();
  d.setSeconds(d.getSeconds() + difference);
  d.setMilliseconds(0);
  return d.getTime();
};

/**
 * Get nested value or undefined.
 *
 * @example
 *
 *   getNested({ level1: 1, level2: 2 }, 'level1.level2');
 *   // 2
 *
 *   getNested({ level1: 1 }, 'level1.level2');
 *   // undefined
 */
const getNested = (obj, path) => path.split('.').reduce((acc, key) => acc && acc[key], obj);

function getDateTickFormatter(domain, timezone, options = {}) {
  // Custom ticks and formatting supporting timezones
  const { scale, width = 2560, ticks: tix, config } = options;

  return (v) => {
    const ticks = tix || scale?.ticks() || [];
    if (!ticks.length) return '';

    const tickIndex = ticks.findIndex((tick) => getTime(tick) === getTime(v));
    const lastTickMs = getTime(ticks[ticks.length - 1]);
    if (getTime(v) === lastTickMs && scale && scale(v) > width - 20) return '';

    const dt = utcToSite(v, timezone);
    if (config && getNested(config, 'axes.x.scale') === 'linear') return dt;

    const year = getYear(v);
    const month = getMonth(v);
    const date = getDate(v);
    const mins = getMinutes(v);
    const secs = getSeconds(v);
    const dtSecs = getSeconds(dt);

    const domainIndex = [
      1000 * 60 * 60 * 24 * 28 * 6, // ~ 6 month
      1000 * 60 * 60 * 24 * 28, // ~ month
      1000 * 60 * 60 * 24 * 14, // 2 week
      1000 * 60 * 60 * 24, // day
      1000 * 60 * 60, // hour
      1000 * 60, // min
      1000, // sec
    ].findIndex((d) => domain > d);

    if (domainIndex === 0) {
      // 6 months +
      if (tickIndex === 0 || getYear(ticks[tickIndex - 1]) !== year) {
        return format(dt, 'yyyy', { timeZone: timezone });
      }
      return format(dt, 'd MMM', { timeZone: timezone });
    }
    if (domainIndex === 1) {
      // at least 30 days, but less than 6 months
      if (tickIndex === 0 || getMonth(ticks[tickIndex - 1]) !== month) {
        return format(dt, 'MMM yyyy', { timeZone: timezone });
      }
      return format(dt, 'd MMM', { timeZone: timezone });
    }
    if (domainIndex === 2) {
      // at least 2 weeks, but less than 1 month
      if (tickIndex === 0 || getMonth(ticks[tickIndex - 1]) !== month) {
        return format(dt, 'MMM yyyy', { timeZone: timezone });
      }
      return format(dt, 'ccc d', { timeZone: timezone });
    }
    if (domainIndex === 3) {
      // at least 24 hours, but less than 2 weeks
      if (tickIndex === 0 || getDate(ticks[tickIndex - 1]) !== date) {
        return format(dt, 'd MMM', { timeZone: timezone });
      }
      return format(dt, 'HH:mm', { timeZone: timezone });
    }
    if (domainIndex === 4) {
      // at least an hour, less than 24 hours
      if (getDate(ticks[tickIndex - 1]) !== date) {
        return format(dt, 'd MMM', { timeZone: timezone });
      }
      return format(dt, 'HH:mm', { timeZone: timezone });
    }
    if (domainIndex === 5) {
      // at least 1 min, less than 1 hour
      if (dtSecs === 0 && getMinutes(ticks[tickIndex - 1]) !== mins) {
        return format(dt, 'HH:mm', { timeZone: timezone });
      }
      return format(dt, ':ss', { timeZone: timezone });
    }
    if (domainIndex === 6) {
      // at least 1 sec, less than 1 min
      if (dtSecs === 0) {
        return format(dt, 'HH:mm', { timeZone: timezone });
      }
      if (tickIndex === 0 || getSeconds(ticks[tickIndex - 1]) !== secs) {
        return format(dt, ':ss', { timeZone: timezone });
      }
    }
    // less than 1 sec
    if (tickIndex === 0 || dtSecs === 0 || getSeconds(ticks[tickIndex - 1]) !== secs) {
      return format(dt, ':ss.SSS', { timeZone: timezone });
    }
    return format(dt, '.SSS', { timeZone: timezone });
  };
}

const getLocalDateString = (date, pattern) => new Date(date).toLocaleDateString(pattern);

const getBestFitGranularity = (intervalInSeconds, desiredDataPoints = 100) => {
  const parsedIntervalInSeconds = parseInt(intervalInSeconds, 10);
  if (parsedIntervalInSeconds === 0) return '60s'; // seconds
  if (parsedIntervalInSeconds) {
    const granularityKeys = GRANULARITY_OPTIONS.map((g) => parseInt(g.value.split('s')[0], 10));
    const bestFit = granularityKeys.find(
      (granularityInSeconds) =>
        Math.abs(parsedIntervalInSeconds / granularityInSeconds) <= desiredDataPoints
    );
    return `${bestFit}s`;
  }
  return '86400s'; // day (default)
};

const getSiteDayStart = (siteDatetime) => {
  const copy = new Date(siteDatetime);
  copy.setHours(0);
  copy.setMinutes(0);
  copy.setSeconds(0);
  copy.setMilliseconds(0);
  return copy;
};

export {
  utcToSite,
  siteToUtc,
  datetimeString,
  getSiteTime,
  zonedDateAndTimeToUtc,
  formatSiteDatetime,
  getMonthFromNow,
  getDayFromNow,
  getMinutesFromNow,
  getHourFromNow,
  getSecondsFromNow,
  getNested,
  getDateTickFormatter,
  getLocalDateString,
  getBestFitGranularity,
  getSiteDayStart,
};
